import { ReactNode } from 'react'
import { unstable_usePrompt } from 'react-router-dom';

import { Form } from 'formik';

export const SafeForm = ({
  className,
  dirty,
  children
}: {
  className?: string
  dirty: boolean
  children: ReactNode
}): JSX.Element => {

  unstable_usePrompt({
    message: "You have unsaved changes on this page.  If you leave without saving you will lose them.",
    when: ({currentLocation, nextLocation }) =>
      dirty &&
      currentLocation.pathname !== nextLocation.pathname,
  });

  return (
    <Form className={`${className || ''} ${dirty ? 'form-dirty' : ''}`}>
      {children}
    </Form>
  );

} 