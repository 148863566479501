export const config = {
  host: process.env.REACT_APP_DB_HOST || 'invalid',
  port: to_number(process.env.REACT_APP_DB_PORT),
  database: process.env.REACT_APP_DB_DATABASE || 'invalid'
}

function to_number(s: string | undefined): number | undefined {
  if (typeof (s) === 'undefined')
    return undefined

  return parseInt(s, 10)
}
