import {Api, DbLiteralTypes, DbResponseResultSet} from './Api';
import {SessionData} from './Session';
import {FileData} from '../components/form';

export class ApplicationNoteDao extends Api {
  constructor(sessionData: SessionData) { super(sessionData) }

  public async query(application_id: number): Promise<DbResponseResultSet> {
    const rows = await this.execute(`
      SELECT
        n.*,
        f.file_name,
        f.mime_type,
        f.id AS file_id,
        f.upload_date AS file_upload_date
      FROM public.application_notes n
      LEFT JOIN public.files f ON n.file_id = f.id
      WHERE n.application_id = :application_id
      ORDER BY n.created_at DESC
    `, {application_id})

    return rows
  }


  public async addNote(application_id: number | string, file: FileData | undefined, note: string): Promise<number> {


    const jsonParam = {
      applicationNote : {
        ...this.emptyStringsToNulls({
          note
        }),
        application_id,
      },
      file: await this.toDbFile(file),
    }

    const r = await this.execute('SELECT app.saveApplicationNote(:json) AS id', {json: JSON.stringify(jsonParam)})

    return r[0].id as number

  }


}
