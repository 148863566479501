import React, {useEffect, useState} from 'react';
import { Link, useNavigate } from 'react-router-dom';

import {inTryCatch} from '../../lib';
import {useAppContext} from '../../context';
import {
  ShipmentDao,
  QueryShipmentsFilters,
  DbResponseResultSet
} from '../../api';

import { Loading } from '../../components'

import { Shipment } from './Shipment'

export function Shipments({
  applicationId,
  shipmentId
}: {
  applicationId: number
  shipmentId?: number | string
}): JSX.Element {

  const { session } = useAppContext()
  const navigate = useNavigate()

  const [loading, setLoading] = useState<boolean>(true)
  const [error, setError] = useState<string>()
  const [forceReload, setForceReload] = useState(0)

  const [shipments, setShipments] = useState<DbResponseResultSet | undefined>()

  useEffect(inTryCatch(setLoading, setError, async (state) => {
    setShipments(undefined)
    if (shipmentId) {

    } else {
      const {rows, count} = await new ShipmentDao(session).query({
        application_id: applicationId
      },{
        limit: 1000, offset: 0, sort: 'shipments.shipment_id', order: 'asc'
      })
      setShipments(rows)
    }
  }), [session, applicationId, shipmentId, forceReload])

  let el;
  if (loading) {
    el = <Loading/>
  } else if (shipmentId) {
    el = (
      <>

        <Shipment id={shipmentId}
          applicationId={applicationId}
          onDelete={() => {
            navigate(`/applications/${applicationId}/shipments`);
          }}
        />
      </>
    )
  } else if (shipments) {
    el = (
      <>
      <table className="table table-striped table-bordered">
        <thead>
          <tr>
            <th>Shipment ID</th>
            <th>PO #</th>
            <th># Tabs</th>
            <th>Airfreight Date</th>
            <th>Arrival Date</th>
            <th>Closeout Date</th>
          </tr>
        </thead>
        <tbody>
          {shipments.map(shipment => (
            <tr key={shipment.id?.toString()}>
              <td>
                <Link to={`/applications/${applicationId}/shipments/${shipment.id}`}>
                  {shipment.shipment_id || 'No ID'}
                </Link>
              </td>
              <td>{shipment.po_number}</td>
              <td>{shipment.total_tablets}</td>
              <td>{shipment.airfreight_date}</td>
              <td>{shipment.actual_arrival_date}</td>
              <td>{shipment.actual_delivery_date}</td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className="text-end">
        <Link className="btn btn-primary"
          to={`/applications/${applicationId}/shipments/new`}
        >
          New Shipment
        </Link>
      </div>
      </>
    )
  } else {
    el = <div>Error</div>
  }

  return (
    <div className="shipments card-body">
      {el}
    </div>
  )
}