export function readFile<T>(file: File, convert: (buf: ArrayBuffer) => T): Promise<T> {
  const p = new Promise<T>((res, rej) => {
    try {
      const reader = new FileReader();
      reader.onload = (e) => {
        try {
          if (!!e?.target?.result && typeof (e.target.result) !== 'string') {
            const val = convert(e.target.result)
            res(val)
            return
          }
          rej(new Error('The file didn\'t return an ArrayBuffer'))
        }
        catch (e) {rej(e)}
      };
      reader.onerror = e => rej(e?.target?.error ?? 'unexpected file error')
      reader.readAsArrayBuffer(file);
    } catch (e) {rej(e)}
  })

  return p
}
