import { ReactNode } from 'react'
import { Loading } from './Loading'

export function LoadablePane({
  className,
  loading,
  children
}: {
  className?: string
  loading?: boolean
  children: ReactNode
}): JSX.Element {
  return (
    <div className={className ?? 'LoadablePane'}>
      {loading ?
        <Loading/>
        :
        children
      }
    </div>
  )
}
