import axios from 'axios';



export function inTryCatch(
  setIsRunning: (v: boolean) => void,
  setError: (e: string | undefined) => void,
  action: (state: { mounted: boolean }) => Promise<void>
): () => () => void {

  return () => {
    const state = {
      mounted: true
    };

    (async () => {
      setIsRunning(true)
      setError(undefined)
      await action(state)
    })()
      .catch((e: any) => {
        setError(errorToString(e))
      })
      .finally(() => {
        setIsRunning(false)
      });

    return () => {state.mounted = false}
  }
}



function errorToString(e: any): string {
  if (typeof (e) === 'string')
    return e

  if (axios.isAxiosError(e)) {
    return 'Unexpected network error'
  }

  if ('message' in e && typeof e.message === 'string') // Error-like objects
    return e.message

  return 'Unexpected error'
}
