import {ReactNode, ClipboardEvent, FocusEvent, useState, useEffect, ChangeEvent} from 'react'
import { capitalize, join, split } from 'lodash'
import { Field, useField, useFormikContext } from 'formik'
import DatePicker from "react-datepicker";

import { FileData, FileInput } from './FileInput';
import { DownloadButton } from '../../components';

import "react-datepicker/dist/react-datepicker.css"

const getContainerClassName = (className: string | undefined): string => (
  `form-group mb-3 ${className || ''}`
)

const afterDot = (str: string) => !str.includes('.') ? str : str.substring(str.lastIndexOf('.') + 1)
const removeEndingId = (str: string) => !str.endsWith('_id') || str === 'app_id' || str === 'batch_id' ? str : str.substring(0, str.lastIndexOf('_id'))

const getFieldLabel = ({name, label}: {name: string, label?: string}): string => (
  label || join(split(removeEndingId(afterDot(name)), '_').map(c => capitalize(c)
    .replaceAll('Ivm','IVM')
    .replaceAll('Pc','PC')
    .replaceAll('Id','ID')
  ), ' ')
)

export function DatePickerInput({...props}:any) {
  const { setFieldValue } = useFormikContext();
  const [field] = useField(props);
  return (
    <DatePicker
      {...field}
      {...props}
      dateFormat={["yyyy-MM-dd", "dd.MM.yyyy"]}
      selected={(field.value && new Date(field.value +"T00:00:00")) || null}
      showYearDropdown={true}
      onChange={val => {
        if (val)
          setFieldValue(field.name, val.toISOString().substring(0, 10));
        else 
          setFieldValue(field.name, '');
      }}
    />
  );
}

export function MonthYearPickerInput({...props}:any) {
  const { setFieldValue } = useFormikContext();
  const [field] = useField(props);
  return (
    <DatePicker
      {...field}
      {...props}
      showMonthYearPicker
      renderMonthContent={(month:any, shortMonth:any, longMonth:any, day:any) => {
        const fullYear = new Date(day).getFullYear();
        const tooltipText = `Tooltip for month: ${longMonth} ${fullYear}`;
        return <span title={tooltipText}>{shortMonth}</span>;
      }}
      dateFormat={["yyyy-MM"]}
      showYearDropdown={true}
      selected={(field.value && new Date(field.value +"T00:00:00")) || null}
      onChange={val => {
        if (val)
          setFieldValue(field.name, val.toISOString().substring(0, 10));
        else 
          setFieldValue(field.name, '');
      }}
    />
  );
}

export function LabeledSelect({
  className,
  name,
  label,
  side,
  labelClass,
  fieldClass,
  children
}: {
  className?: string
  name: string
  label?: string
  side?: boolean
  labelClass?: string
  fieldClass?: string
  children: ReactNode
}) {
  return (
    <>
      {side &&
        <div className={className}>
          <div className="mb-3 row">
            <label className={`${labelClass ? labelClass : "col-sm-9"} col-form-label`}>
              {getFieldLabel({name, label})}
            </label>
            <div className={`${fieldClass ? fieldClass : "col-sm-9"}`}>
              <Field className="form-select" as="select" name={name}>
                {children}
              </Field>
            </div>
          </div>
        </div>
      }
      {!side &&
          <div className={getContainerClassName(className)}>
              <label>{getFieldLabel({name, label})}</label>
              <Field className="form-select" as="select" name={name}>
                {children}
              </Field>
          </div>
      }
    </>
  ) 
}


function IntegerField(
  {
    className,
    name,
  }: {
    className?: string
    name: string
  }) {

  const [f, m, helpers] = useField(name)
  const { getFieldProps } = useFormikContext()
  const [text, setText] = useState('')

  useEffect(() => {
    if (f.value) {
      if (typeof (f.value) === 'number')
        setText(f.value.toLocaleString('en-us'))
      else
        setText(f.value)
    }
  }, [])

  function cleanPastedText(clipboardData: string): string | number {
    const zero = '0'.codePointAt(0) ?? 0
    const nine = '9'.codePointAt(0) ?? 0

    if (!clipboardData)
      return clipboardData

    let cleanInt = ''
    for(let i = 0; i < clipboardData.length; i++) {
      const codePoint = clipboardData.codePointAt(i)
      if (!!codePoint && codePoint >= zero && codePoint <= nine || clipboardData.charAt(i) === '.')
        cleanInt += clipboardData.charAt(i)
    }

    const val = parseFloat(cleanInt)

    if (isNaN(val))
      return clipboardData

    return val
  }

  function handlePaste(e: ClipboardEvent<HTMLInputElement>) {
    if (e.isTrusted) {
      const newVal = cleanPastedText(e.clipboardData.getData('Text'))
      helpers.setValue(newVal)
      setTimeout(() => {
        if (typeof (newVal) === 'number')
          setText(newVal.toLocaleString('en-us'))
        else
          setText(newVal)
      }, 20)
    }
  }

  const {value, onBlur, onChange, ...otherProps} = getFieldProps(name)

  function myOnChange(e: ChangeEvent<HTMLInputElement>) {
    const inputText = e.currentTarget.value + ''
    const justNumeric = inputText.match(/^[\d\,]*[\d\.]*$/g)

    if (!justNumeric) {
      helpers.setValue(inputText)
      setText(inputText)
      return
    }

    const cleanNum = inputText.replaceAll(',', '')
    const asNum = parseFloat(cleanNum)
    if (isNaN(asNum)) {
      helpers.setValue(cleanNum)
    } else {
      helpers.setValue(asNum)
    }
    setText(inputText)
  }

  function myOnBlur(e: FocusEvent<HTMLInputElement>) {
    const inputText = text
    const justNumeric = inputText.match(/^[\d\,]*[\d\.]*$/g)

    if (justNumeric) {
      const cleanNum = (inputText + '').replaceAll(',', '')
      const asNum = parseFloat(cleanNum)
      if (isNaN(asNum)) {
        setText(cleanNum)
        helpers.setValue(cleanNum)
      } else {
        setText(asNum.toLocaleString('en-us'))
        helpers.setValue(asNum)
      }
    }

    onBlur(e)
  }


  return <input {...otherProps} value={text} onChange={myOnChange} onBlur={myOnBlur} className={className} name={name} onPaste={handlePaste} />
}




export function LabeledInput({
  className,
  name,
  type,
  label,
  labelClass,
  fieldClass,
  side,
  integer,
}: {
  className?: string
  name: string
  type?: string
  label?: string
  labelClass?: string
  fieldClass?: string
  side?: boolean
  integer?: boolean
}) {
  let field = <></>
  if (type == 'date') {
    field = <DatePickerInput className="form-control" name={name} />
  } else if (type == 'monthyear') {
    field = <MonthYearPickerInput className="form-control" name={name} />
  } else if (integer) {
    field = <IntegerField className="form-control" name={name} />
  } else {
    field = <Field className="form-control" type={type || 'text'} name={name} />
  }
  return (
    <>
      {side &&
        <div className={className}>
          <div className="mb-3 row">
            <label className={`${labelClass ? labelClass : "col-md-6"} col-form-label`}>
              {getFieldLabel({name, label})}
            </label>
            <div className={`${fieldClass ? fieldClass : "col-md-6"}`}>
              {field}
            </div>
          </div>
        </div>
      }
      {!side &&
        <div className={getContainerClassName(className)}>
          <label>{getFieldLabel({name, label})}</label>
              {field}
        </div>
      }
    </>
  )
}


export function LabeledTextArea(
  {
    className,
    name,
    label,
  }: {
    className?: string
    name: string
    label?: string
  }
) {
  return (
    <div className={getContainerClassName(className)}>
        <label>{getFieldLabel({name, label})}</label>
        <Field className="form-control" as="textarea" name={name} />
    </div>
  )
}

export function LabeledSwitch(
  {
    className,
    name,
    label,
    side,
  }: {
    className?: string
    name: string
    label?: string
    side?: boolean
  }) {
  return (
    <>
      {side &&
          <div className={className}>
              <div className="mb-3 d-flex">
                  <div className="form-check form-switch my-auto">
                      <Field className="form-check-input" type="checkbox" name={name} />
                  </div>
                  <label className="col-sm-3 col-form-label">{getFieldLabel({name, label})}</label>
              </div>
          </div>
      }
      {!side &&
          <div className={getContainerClassName(className)}>
              <label className="form-check-label">{getFieldLabel({name, label})}</label>
              <div className={'form-check form-switch'}>
                  <Field className="form-check-input" 
                    type="checkbox" 
                    name={name}
                    style={{width: '3.5rem', height: '1.8rem'}}
                  />
              </div>
          </div>
      }
    </>
  )
}



export function LabeledFileInput(
  {
    className,
    label,
    side,
    value,
    onChange,
    showUpdated,
    boldLabel,
    labelClass,
    fieldClass
  }: {
    className?: string
    label: string
    side?: boolean
    value: FileData | undefined
    onChange: (val: FileData | undefined) => void
    showUpdated?: boolean
    boldLabel?: boolean
    labelClass?: string
    fieldClass?: string
  }) {

  return (
    <>
      {side &&
          <div className={className + ' d-flex align-items-center'}>
            <label className={`${labelClass ? labelClass : "col-md-6"} col-form-label`}>{label}</label>
            <FileInput className={`${fieldClass ? fieldClass : "col-md-6"}`} onChange={onChange} value={value}/>
          </div>
      }
      {!side && 
          <div className={className}>
            <label className={`${labelClass ? labelClass : ""} form-label`}>{label}</label>
            <FileInput className={`${fieldClass ? fieldClass : ""}`} onChange={onChange} value={value}/>
          </div>
      }
    </>
  )
}


