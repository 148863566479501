import {FieldArray, FormikValues, useFormikContext} from 'formik';
import React from 'react';

function range(start: number, end: number): number[] {
  if (start > end)
    return []

  const result = []
  for(let i = start; i<= end; i++)
    result.push(i)
  return result
}

export function FieldArrayPanel(
  {
    className,
    name,
    title,
    addTitle,
    newItem,
    element,
  } : {
    className?: string
    name: string
    title: string
    addTitle: string
    newItem: () => FormikValues
    element: (index: number, remove: (i: number) => any | undefined) => React.ReactNode
  }
): JSX.Element {
  const { values } = useFormikContext<FormikValues>();

  return (
    <FieldArray name={name}>
      {({ remove, push }) => {
        const confirmRemove = (i: number) => {
          if (window.confirm("Are you sure you wish to remove this item?")) {remove(i)}
        }
        return (
          <div className={className || ''}>
            <div className="d-flex justify-content-between">
              <h5>{title}</h5>
              <button className="btn btn-sm btn-light btn-tab-top me-0" type='button' onClick={() => push(newItem())}>{addTitle}</button>
            </div>
            {values && values[name].map((_: number, index: number) => element(index, confirmRemove))}
          </div>
        )
      }}
    </FieldArray>
  )
}
