import {useEffect, useMemo, useState} from 'react';
import {
  DbResponseResultSet,
  DbResponseResultRow,
  ApplicationNoteDao,
} from '../../api';
import {inTryCatch} from '../../lib';
import {useAppContext} from '../../context';
import {FileData, FormError} from '../../components/form';
import {LoadablePane} from '../../components';
import {FileInput} from '../../components/form/FileInput';
import {DownloadButton} from '../../components';

function ExistingNote(
  {
    note,
  } : {
    note: DbResponseResultRow
  }

): JSX.Element {


  return (
  <div className="card mb-2">
    <div className="card-body">
      <div className=" ">{note.note}</div>
      <DownloadButton fileId={note?.file_id as number} fileName={note?.file_name as string} label={note?.file_name as string ?? 'download'} />
    </div>
    <div className="card-footer d-flex justify-content-end align-items-center">
      <div>{new Date(note.created_at as string).toDateString()}</div>
      <div className="mx-2 mb-1">|</div>
      <div>{note.created_user}</div>
    </div>
  </div>
  )
}


export function Notes(
  {
    application_id
  } : {
    application_id: number | undefined
  }
): JSX.Element {

  const { session } = useAppContext()

  const [notes, setNotes] = useState<DbResponseResultSet>([])
  const [error, setError] = useState<string>()
  const [loading, setLoading] = useState<boolean>(true)
  const [newNote, setNewNote] = useState<string>('')
  const [newNoteFile, setNewNoteFile] = useState<FileData>()
  const [forceReload, setForceReload] = useState(0)


  useEffect(inTryCatch(setLoading, setError, async (state) => {
    setNotes([])
    if (!application_id) return
    const notes = await new ApplicationNoteDao(session).query(application_id)
    if (!state.mounted) return
    setNotes(notes)
  }), [session, application_id, forceReload])

  function saveNote() {
    (async () => {
      setError(undefined)
      if (!application_id) return
      await new ApplicationNoteDao(session).addNote(application_id, newNoteFile, newNote)
      setForceReload(p => p + 1)
      setNewNote('')
    })().catch(e => setError(e.message))
  }

  return (
    <div className="card">
      <div className='card-header'>Notes</div>
      <div className="card-body">
        {!!application_id &&
            <LoadablePane loading={loading}>
                <div className="input-group">
                    <textarea className="form-control w-100" value={newNote}
                              onChange={e => setNewNote(e.target.value)}/>
                    <div className="d-flex justify-content-end align-items-center w-100">
                        <button className="btn btn-secondary btn-sm my-2" onClick={saveNote}
                                disabled={newNote.trim() === ''}>Post
                        </button>
                    </div>
                </div>
                <FormError error={error}/>
              {notes.map(n => (<ExistingNote note={n} key={+(n.id ?? 0)}/>))}
            </LoadablePane>
        }
      </div>
    </div>
  )
}
