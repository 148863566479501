import {Api} from './Api';
import {SessionData} from './Session';

export class FileDao extends Api {
  constructor(sessionData: SessionData) { super(sessionData) }

public async downloadAsDataUrl(id: number): Promise<string | undefined> {
    const r = await this.execute(`
      SELECT 'data:' || f.mime_type || ';base64,' || encode(data, 'base64') AS url
      FROM public.file_data d
      JOIN public.files f ON d.file_id = f.id
      WHERE f.id = :id;
    `, {id})

    return r[0]?.url as string | undefined
  }
}
