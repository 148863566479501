import {createContext, useContext} from 'react';
import {SessionData} from '../api';

export type AppContent = {
  session: SessionData
  logout: () => void
}
export const AppContext = createContext<AppContent>({
  session: {
    user: '',
    password: ''
  },
  logout: () => null
})

export const useAppContext = () => useContext(AppContext)
