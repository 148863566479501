import { MouseEvent } from 'react'

import { LoadingIcon } from '../icons'

export function FormButtons({
  className,
  buttons
}: {
  className?: string
  buttons: {
    className?: string
    label: string
    type?: "button" | "submit" | "reset"
    disabled?: boolean
    submitting?: boolean
    hidden?: boolean
    onClick?: (e: MouseEvent<HTMLButtonElement>, value?: boolean) => void
  }[]
}) {

  const SpinnerStyle = {
    width: '16px',
    height: '16px',
    marginTop: '-2px',
    marginRight: '5px'
  };

  // Combine defaults with optional parameters
  const containerClassName = `form-group  ${className || 'mt-2'}`

  // Collate buttons
  const buttonElements = buttons.map((button) => (
    <button key={button.label}
      className={button.className || `btn btn-primary me-1`} 
      type={button.type || 'submit'} 
      disabled={button.disabled}
      onClick={button.onClick}
      style={button.hidden ? {display: 'none'} : {}}
    >
      {button.submitting &&
        <LoadingIcon className="animate-spinner" style={SpinnerStyle}/>
      }
      {button.label}
    </button>
  ))

  return (
    <div className={containerClassName}>
      {buttonElements}
    </div>
  )
}
