export function DangerAlert(
  {
    text,
    className,
  } : {
    text: string | undefined
    className?: string
  }
): JSX.Element {
  return (
    <>
    {text &&
      <div className={'alert alert-danger' + (!className ? '' : ' ' + className)} role="alert">
        {text}
      </div>
    }
    </>
  )
}
