import {
  Api,
  DbLiteralTypes,
  DbResponseResultSet,
  PagingParams,
  SortingInfo, 
  WhereBuilder
} from './Api';
import {SessionData} from './Session';
import {FormikValues} from 'formik';
import {FileData} from '../components/form';
import {omit} from 'lodash';


export interface QueryShipmentsFilters {
  application_id?: number
  country_name?: string
  request_year?: string
  status?: string
  shipment_ids?: string
}

export class ShipmentDao extends Api {
  constructor(sessionData: SessionData) { super(sessionData) }


  public async query(
    filters: QueryShipmentsFilters,
    paging: PagingParams
  ): Promise<{rows: DbResponseResultSet, count: number}> {
    const queryParams: Record<string, DbLiteralTypes> = {
      ...filters,
      ...paging,
    }
    const whereBuilder = new WhereBuilder()
    if (filters.application_id)
      whereBuilder.and(filters.application_id, `cte.application_id = :application_id`)
    const whereClause = whereBuilder.build()
    const cte = `
      SELECT shipments.*
      FROM public.shipments
      JOIN public.applications on applications.id = shipments.application_id
    `;
    const [rows, countRows] = await Promise.all([
      this.execute(`
        with cte as (${cte}) select * from cte ${whereClause}
        order by cte.shipment_id limit :limit offset :offset
      `, queryParams),
      this.execute(`
        with cte as (${cte}) select count(*) from cte ${whereClause}
      `, queryParams)
    ])
    return {
      rows: rows,
      count: countRows[0].count as number
    }
  }

  public async get(id: number): Promise<{
    shipment: FormikValues | undefined,
    donationCertificateFile: FileData | undefined
    invoiceFile: FileData | undefined
    shippingMemoFile: FileData | undefined
    poEmailFile: FileData | undefined
    arPackingListFile: FileData | undefined
    closeoutReceiptFile: FileData | undefined
  }> {

    const shipmentRow = await this.execute(`
      select 
        s.*,
        dcf.file_name   AS donation_certificate_file_name,
        dcf.mime_type   AS donation_certificate_mime_type,
        dcf.id          AS donation_certificate_file_id,
        dcf.upload_date AS donation_certificate_file_upload_date,      
        
        ivf.file_name   AS invoice_file_name,
        ivf.mime_type   AS invoice_mime_type,
        ivf.id          AS invoice_file_id,
        ivf.upload_date AS invoice_file_upload_date,          
        
        smf.file_name   AS shipping_memo_file_name,
        smf.mime_type   AS shipping_memo_mime_type,
        smf.id          AS shipping_memo_file_id,
        smf.upload_date AS shipping_memo_file_upload_date,            
        
        pef.file_name   AS po_email_file_name,
        pef.mime_type   AS po_email_mime_type,
        pef.id          AS po_email_file_id,
        pef.upload_date AS po_email_file_upload_date,                  
        
        plf.file_name   AS ar_packing_list_file_name,
        plf.mime_type   AS ar_packing_list_mime_type,
        plf.id          AS ar_packing_list_file_id,
        plf.upload_date AS ar_packing_list_file_upload_date,

        shippers.id     AS shipper_id,
        shippers.name   AS shipper_name
         
      from public.shipments s
      left join public.files dcf ON s.donation_certificate_file_id   = dcf.id
      left join public.files ivf ON s.invoice_file_id                = ivf.id
      left join public.files smf ON s.shipping_memo_file_id          = smf.id
      left join public.files pef ON s.po_email_file_id               = pef.id
      left join public.files plf ON s.ar_packing_list_file_id        = plf.id
      left outer join public.shippers on s.shipper_id = shippers.id
      where s.id = :id
      `, {id})

    const batchRows = await this.execute(`SELECT * FROM public.shipment_batches b WHERE b.shipment_id = :shipment_id`, {shipment_id: id})

    const closeoutRow = await this.execute(`
        SELECT 
            b.*,

            crf.file_name   AS receipt_file_name,
            crf.mime_type   AS receipt_mime_type,
            crf.id          AS receipt_file_id,
            crf.upload_date AS receipt_file_upload_date
        FROM public.shipment_closeouts b
        left join public.files crf ON b.receipt_file_id   = crf.id
        WHERE b.shipment_id = :shipment_id`, {shipment_id: id})

    return {
      shipment: {
        ...this.nullsToEmptyStrings(omit(shipmentRow[0],
        'donation_certificate_file_name',
        'donation_certificate_mime_type',
        'donation_certificate_file_id',
        'donation_certificate_file_upload_date',

        'invoice_file_name',
        'invoice_mime_type',
        'invoice_file_id',
        'invoice_file_upload_date',

        'shipping_memo_file_name',
        'shipping_memo_mime_type',
        'shipping_memo_file_id',
        'shipping_memo_file_upload_date',

        'po_email_file_name',
        'po_email_mime_type',
        'po_email_file_id',
        'po_email_file_upload_date',

        'ar_packing_list_file_name',
        'ar_packing_list_mime_type',
        'ar_packing_list_file_id',
        'ar_packing_list_file_upload_date',
        )),
        batches: batchRows.map(b => this.nullsToEmptyStrings(b)!),
        closeout: this.nullsToEmptyStrings(omit(closeoutRow[0],
          'receipt_file_name',
          'receipt_mime_type',
          'receipt_file_id',
          'receipt_file_upload_date')),
      },
      donationCertificateFile: shipmentRow[0]?.donation_certificate_file_id
        ? {
          name:         shipmentRow[0].donation_certificate_file_name as string,
          type:         shipmentRow[0].donation_certificate_mime_type as string,
          file_id:      shipmentRow[0].donation_certificate_file_id as number,
          upload_date:  shipmentRow[0].donation_certificate_file_upload_date as string,
        }
        : undefined,
      invoiceFile: shipmentRow[0]?.invoice_file_id
        ? {
          name:         shipmentRow[0].invoice_file_name as string,
          type:         shipmentRow[0].invoice_mime_type as string,
          file_id:      shipmentRow[0].invoice_file_id as number,
          upload_date:  shipmentRow[0].invoice_file_upload_date as string,
        }
        : undefined,
      shippingMemoFile: shipmentRow[0]?.shipping_memo_file_id
        ? {
          name:         shipmentRow[0].shipping_memo_file_name as string,
          type:         shipmentRow[0].shipping_memo_mime_type as string,
          file_id:      shipmentRow[0].shipping_memo_file_id as number,
          upload_date:  shipmentRow[0].shipping_memo_file_upload_date as string,
        }
        : undefined,
      poEmailFile: shipmentRow[0]?.po_email_file_id
        ? {
          name:         shipmentRow[0].po_email_file_name as string,
          type:         shipmentRow[0].po_email_mime_type as string,
          file_id:      shipmentRow[0].po_email_file_id as number,
          upload_date:  shipmentRow[0].po_email_file_upload_date as string,
        }
        : undefined,
      arPackingListFile: shipmentRow[0]?.ar_packing_list_file_id
        ? {
          name:         shipmentRow[0].ar_packing_list_file_name as string,
          type:         shipmentRow[0].ar_packing_list_mime_type as string,
          file_id:      shipmentRow[0].ar_packing_list_file_id as number,
          upload_date:  shipmentRow[0].ar_packing_list_file_upload_date as string,
        }
        : undefined,
      closeoutReceiptFile: closeoutRow[0]?.receipt_file_id
        ? {
          name:         closeoutRow[0].receipt_file_name as string,
          type:         closeoutRow[0].receipt_mime_type as string,
          file_id:      closeoutRow[0].receipt_file_id as number,
          upload_date:  closeoutRow[0].receipt_file_upload_date as string,
        }
        : undefined,
    }

  }

  public async save(values: FormikValues,
                    application_id: number,
                    donation_certificate_file: FileData | undefined,
                    invoice_file: FileData | undefined,
                    shipping_memo_file: FileData | undefined,
                    po_email_file: FileData | undefined,
                    ar_packing_list_file: FileData | undefined,
                    closeout_receipt_file: FileData | undefined): Promise<number> {



    const jsonParam = {
      shipment : {
        ...this.emptyStringsToNulls(values),
        application_id,
      },
      donation_certificate_file: await this.toDbFile(donation_certificate_file),
      invoice_file: await this.toDbFile(invoice_file),
      shipping_memo_file: await this.toDbFile(shipping_memo_file),
      po_email_file: await this.toDbFile(po_email_file),
      ar_packing_list_file: await this.toDbFile(ar_packing_list_file),
      closeout_receipt_file: await this.toDbFile(closeout_receipt_file),
    }

    const r = await this.execute('SELECT app.saveShipment(:json) AS id', {json: JSON.stringify(jsonParam)})


    return r[0].id as number
  }

  public async delete(id: number): Promise<void> {
    await this.execute(`CALL app.delete_shipment(:id)`, {id})
  }

}
