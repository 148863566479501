import {JRSMParserConfig} from './types';
import jrsmv40 from './jrsmv40';

export default {
  ...jrsmv40,
  request_district_data: {
    ...jrsmv40.request_district_data,
    fields: jrsmv40.request_district_data.fields.map(f => {
      switch (f.name) {
        case 'rounds_lf': return {...f, column: 'W'}
        case 'rounds_oncho': return {...f, column: 'X'}
      }
      return f
    })
  }
} as JRSMParserConfig
