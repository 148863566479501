import moment from 'moment'

export type SessionData = {
  user: string
  password: string
}

type StoredData = {
  data: SessionData
  expires: string
}

const expireDays = 31
const key = 'session-data'

export class Session {

  set(data: SessionData | null) {
    if (data === null) {
      localStorage.removeItem(key)
    } else {
      localStorage.setItem(key, JSON.stringify({
        data: data,
        expires: moment().add(expireDays, 'days').format()
      }));
    }
  }

  get(): SessionData|null {
    const stringData = localStorage.getItem(key);
    const storedData = stringData ? <StoredData>JSON.parse(stringData) : null
    if (storedData) {
      if (moment().isBefore(moment(storedData.expires))) {
        return storedData.data
      } else {
        localStorage.removeItem(key)
      }
    }
    return null
  }

}
