import {useEffect, useMemo, useState} from 'react';
import {Link, useSearchParams} from 'react-router-dom';
import {
  ApplicationDao,
  PagingParams, 
  QueryApplicationsFilters, 
  DbResponseResultSet, 
  ApplicationSortInfo,
  DbLiteralTypes
} from '../api';
import {useAppContext} from '../context';
import {inTryCatch} from '../lib';
import {
  ApplicationStatus,
  DangerAlert,
  GenericSearchTableHeader,
  Pager
} from '../components';


export function Applications(): JSX.Element {


  const [searchParams, setSearchParams] = useSearchParams()
  const { session, logout } = useAppContext()
  const [applications, setApplications] = useState<DbResponseResultSet | undefined>()
  const [count, setCount] = useState(0)
  const [isLoading, setIsLoading] = useState(true)
  const [error, showError] = useState<string>()

  const {paging, filter} = useMemo(() => {
    const paging: PagingParams = {
      limit: 50,
      offset: 0,
      sort: 'request_year',
      order: 'desc',
    }

    if (searchParams.get('limit')) paging.limit = parseInt(searchParams.get('limit') ?? '', 10)
    if (searchParams.get('offset')) paging.offset = parseInt(searchParams.get('offset') ?? '', 10)
    if (searchParams.get('sort')) paging.sort = searchParams.get('sort') ?? 'app_id'
    if (searchParams.get('order')) paging.order = searchParams.get('order') === 'asc' ? 'asc' : 'desc'

    const filter: QueryApplicationsFilters = {
      app_id: '',
      country_name: '',
      request_year: '',
      status: '',
      shipment_ids: '',
      shipment_statuses: '',
      scope: ''
    }

    if (searchParams.get('app_id')) filter.app_id = searchParams.get('app_id') ?? ''
    if (searchParams.get('country_name')) filter.country_name = searchParams.get('country_name') ?? ''
    if (searchParams.get('request_year')) filter.request_year = searchParams.get('request_year') ?? ''
    if (searchParams.get('status')) filter.status = searchParams.get('status') ?? ''
    if (searchParams.get('shipment_ids')) filter.shipment_ids = searchParams.get('shipment_ids') ?? ''
    if (searchParams.get('shipment_statuses')) filter.shipment_statuses = searchParams.get('shipment_statuses') ?? ''
    if (searchParams.get('scope')) filter.scope = searchParams.get('scope') ?? ''

    return {paging, filter}
  }, [searchParams])

  function setOffset(offset: number) {
    setSearchParams(prev => {
      prev.set("offset", offset.toString())
      return prev
    }, {replace: true})
  }

  function changeFilter(prop: keyof QueryApplicationsFilters, val: string) {
    setSearchParams(prev => {
      prev.set(prop, val)
      prev.set('offset', '0')
      return prev
    }, {replace: true})
  }

  function changeSort(prop: string) {
    const newDirection = paging.sort === prop
      ? (
        paging.order === 'asc' ? 'desc' : 'asc'
      ) : 'asc'

    setSearchParams(prev => {
      prev.set('order', newDirection)
      prev.set('sort', prop)
      prev.set('offset', '0')
      return prev
    }, {replace: true})
  }

  function shipmentIdsElement(
    shipment_ids: DbLiteralTypes, 
    shipment_statuses: DbLiteralTypes
  ): any {
    if (typeof shipment_ids === 'string' && typeof shipment_statuses === 'string') {
      let el = []
      const shipment_ids_array = shipment_ids.split(',')
      const shipment_statuses_array = shipment_statuses.split(',')
      for (let i = 0; i < shipment_ids_array.length; i++) {
        el.push(
          <div key={shipment_ids_array[i]}>
            {shipment_ids_array[i]} <strong>{shipment_statuses_array[i]}</strong>
          </div>
        )
      }
      return el;
    } else {
      return shipment_ids;
    }
  }

  function setScope(scope: string) {
    setSearchParams(prev => {
      prev.set("scope", scope)
      return prev
    }, {replace: true})
  }

  useEffect(inTryCatch(setIsLoading, showError, async(state) => {
      const appsInfo = await new ApplicationDao(session).query(filter, paging)
      if (!state.mounted) return
      setApplications(appsInfo.rows)
      setCount(appsInfo.count)
    }), [paging, filter, session])

  return (
    <>
      <DangerAlert text={error} />
      <div className="row mb-2">
        <div className="col">
          <ul className="nav nav-tabs">
            <li className="nav-item">
              <button className={`nav-link ${!searchParams.get('scope') || searchParams.get('scope') === '' ? 'active' : ''}`}
                onClick={() => setScope('')}
                title="Any applications not Completed and Closed Out"
              >
                In Progress / Open
              </button>
            </li>
            <li className="nav-item">
              <button className={`nav-link ${searchParams.get('scope') === 'all' ? 'active' : ''}`}
                onClick={() => setScope('all')}
                title="All applications"
              >
                All
              </button>
            </li>
          </ul>
        </div>
        <div className="col-md-auto text-md-end">
          <Pager count={count} pager={paging} setOffset={setOffset} />
        </div>
      </div>
      <table className="table table-striped table-bordered">
        <thead>
        <tr>
          <GenericSearchTableHeader 
            title="Application ID" 
            prop='app_id'
            filter={filter}
            setFilter={changeFilter} 
            paging={paging}
            changeSort={changeSort}
            sortInfo={ApplicationSortInfo} 
          />
          <GenericSearchTableHeader
            title="Country" 
            prop='country_name' 
            filter={filter}
            setFilter={changeFilter} 
            paging={paging} 
            changeSort={changeSort} 
            sortInfo={ApplicationSortInfo} 
          />
          <GenericSearchTableHeader 
            title="Request Year" 
            prop='request_year' 
            filter={filter}
            setFilter={changeFilter} 
            paging={paging} 
            changeSort={changeSort} 
            sortInfo={ApplicationSortInfo} 
          />
          <GenericSearchTableHeader 
            title="Application Status" 
            prop='status' 
            filter={filter}
            setFilter={changeFilter} 
            paging={paging} 
            changeSort={changeSort} 
            sortInfo={ApplicationSortInfo}
            />
          <GenericSearchTableHeader 
            title="Shipments" 
            prop='shipment_ids' 
            filter={filter}
            setFilter={changeFilter} 
            paging={paging} 
            changeSort={changeSort} 
            sortInfo={ApplicationSortInfo} 
          />
          <th scope="col"></th>
        </tr>
        </thead>
        <tbody>
        {
          applications?.map(row => (
            <tr key={row.app_id?.toString()}>
              <th scope="row" className="text-nowrap">{row.app_id}</th>
              <td>{row.country_name}</td>
              <td>{row.request_year}</td>
              <td><ApplicationStatus status={row.status} /></td>
              <td>{shipmentIdsElement(row.shipment_ids, row.shipment_statuses)}</td>
              <td><Link to={`/applications/${row.id}`}>view</Link></td>
            </tr>
          ))
        }
        </tbody>
      </table>
    </>

  )
}
