import { useState } from 'react'

import {ApplicationDao, LoginDao, SessionData} from '../api'
import {Formik, Form, FormikValues} from 'formik';
import * as Yup from 'yup';

import { FormButtons, FormError, LabeledInput } from '../components/form'

export function Login({
  setSession
}: {
  setSession: (session: SessionData) => void
}) {

  const [error, setError] = useState<string>()

  const login = async (values: FormikValues, setSubmitting: (v: boolean) => void) => {
    const proposedSession: SessionData = {
      user: values.username,
      password: values.password
    }

    const dao = new LoginDao(proposedSession)

    try {
      await dao.login();
      setSession(proposedSession)
    } catch (e: any) {
      setError(e.message)
    } finally {
      setSubmitting(false)
    }
  }


  return (
    <div className="card mt-5 mx-auto" style={{maxWidth: '400px'}}>
      <div className="card-body">
        <h5 className="card-title">Sign In</h5>
        <div className="card-text">
          Sign into the applications management app with your database credentials
          <Formik
            initialValues={{ username: '', password: '' }}
            onSubmit={async (values, { setSubmitting }) => (
              await login(values, setSubmitting)
            )}
          >
            {({ isSubmitting }) => (
              <Form className="mt-2">
                <FormError error={error} />
                <LabeledInput name="username" />
                <LabeledInput name="password" type="password" />
                <FormButtons 
                  buttons={[{
                    label: 'Sign In',
                    disabled: isSubmitting
                  }]}
                />
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  )
}
