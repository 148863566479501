import { useState } from 'react'

import { parseJRSM } from '.'
import { read } from 'xlsx'

export function TestParse() {

  const [output, setOutput] = useState<string>("")

  const parseFile = async (e:any) => {
    var reader = new FileReader();
    reader.onload = (e) => {
      const result = parseJRSM(read(e?.target?.result))
      setOutput(JSON.stringify(result, null, 2))
    };
    reader.readAsArrayBuffer(e.target.files[0]);
  }

  return (
    <form>
      <p>Test Handle JRSM upload</p>
      <input type="file" onChange={parseFile}/>
      <pre>
        {output}
      </pre>
    </form>
  )
}