import {JRSMParseResult, JRSMParseResultSection} from '../parser';
import {DbResponseResultRow, RequestDao, RequestDistrictDataDao, SessionData} from '../api';
import {FormikValues} from 'formik';
import {groupBy, keys, pickBy, uniq} from 'lodash';

function ciEquals(a : string | number, b: string | number) {
  return typeof a === 'string' && typeof b === 'string'
    ? a.localeCompare(b, undefined, { sensitivity: 'accent' }) === 0
    : a === b;
}

function match(newRows: FormikValues[], existingRows: FormikValues[], matchBy: (c: FormikValues) => Record<string, string | number>): FormikValues[] {
  for (const newRow of newRows) {
    const rowKey = matchBy(newRow)
    const found = existingRows.filter(r => keyEquals(matchBy(r), rowKey))[0]
    if (found) {
      newRow.id = found.id
      existingRows = existingRows.filter(r => r !== found)
    }
  }

  return newRows
}

function keyEquals(a: Record<string, string | number>, b: Record<string, string | number>): boolean {
  for (const prop of Object.keys(a)) {
    if (!ciEquals(a[prop], b[prop]))
      return false
  }

  return true
}

export async function convertJrsmResultToRequest(jrsmParseResult: JRSMParseResult, session: SessionData, applicationId: number, requestId: number | undefined): Promise<FormikValues> {

  if (jrsmParseResult.requests.length !== 1)
    throw new Error('There should be just one Request in the sheet')


  const request = toFormikRow(jrsmParseResult.requests[0])
  const existingRequest = (await new RequestDao(session).get(requestId)) ?? {}
  if (!!requestId) {
    request.id = requestId
  }

  request.districts = await matchRequestDistricts(jrsmParseResult.request_district_data, existingRequest.districts ?? [], session, applicationId);

  request.contacts = match(jrsmParseResult.request_contacts.map(toFormikRow), existingRequest.contacts ?? [], c => c.name)
  request.contributors = match(jrsmParseResult.request_contributors.map(toFormikRow), existingRequest.contributors ?? [], c => c.name)
  request.funding = match(jrsmParseResult.request_funding.map(toFormikRow), existingRequest.funding ?? [], c => c.disease)
  request.notTargeted = match(jrsmParseResult.request_not_targeted.map(toFormikRow), existingRequest.notTargeted ?? [], c => c.pc_medicine)


  return request
}

function toFormikRow(r: JRSMParseResultSection): FormikValues {

  const result: FormikValues = { }
  if (r.fields)
    for (const cell of r.fields) {
      if (cell.db)
        result[cell.name] = (cell.value === null || typeof cell.value === 'undefined' ) ? '' : cell.value
      else if (cell.name === 'district')
        result['jrsm_district_name'] = cell.value as string
      else if (cell.name === 'region')
        result['jrsm_region_name'] = cell.value as string
    }
  return result

}




function build2LevelDistrictMap(districtRows: DbResponseResultRow[], useSubRegionNamesInstead: boolean = false): Record<string, Record<string, number>> {
  const tempMap: Record<string, {name: string, alt_names: string[], id: number, districts: Record<string, {name: string, id: number, alt_names: string[]}>}> = {}


  const districtRowsWithParent = useSubRegionNamesInstead
    ? districtRows.map(r => ({
      parent_id: r.subregion_id as number,
      parent_name: r.subregion_name as string,
      parent_alt_names: r.subregion_alt_names as string[],
      district_name: r.district_name as string,
      district_alt_names: r.district_alt_names as string[],
      id: r.id as number,
    }))
    : districtRows.map(r => ({
      parent_id: r.region_id as number,
      parent_name: r.region_name as string,
      parent_alt_names: r.region_alt_names as string[],
      district_name: r.district_name as string,
      district_alt_names: r.district_alt_names as string[],
      id: r.id as number,
    }))

  if (districtRowsWithParent.filter(r => !r.parent_id).length > 0)
    throw new Error(`${useSubRegionNamesInstead ? 'Subregion' : 'Region'} missing for districts: ${districtRowsWithParent.filter(r => !r.parent_id).map(r => r.district_name).join()}`)

  const lowerCaseDistrictRows = districtRowsWithParent.map(r => ({
    parent_id: r.parent_id,
    parent_name: r.parent_name.toLowerCase(),
    parent_alt_names: r.parent_alt_names.map(n => n.toLowerCase()),
    district_name: (r.district_name as string).toLowerCase(),
    district_alt_names: (r.district_alt_names as string[]).map(n => n.toLowerCase()),
    id: r.id as number,
  }))


  for(const row of lowerCaseDistrictRows) {
    if (!(row.parent_name in tempMap))
      tempMap[row.parent_name] = {
        name: row.parent_name,
        alt_names: row.parent_alt_names,
        districts: {},
        id: row.parent_id
      }

    const regionMap = tempMap[row.parent_name]
    if (regionMap.id !== row.parent_id)
      throw new Error(`${useSubRegionNamesInstead ? 'Subregion' : 'Region'} name, ${regionMap.name}(${regionMap.id}), conflicts with ${row.parent_name}(${row.parent_id})`)

    if (regionMap.districts[row.district_name])
      throw new Error(`Duplicate district found in ${useSubRegionNamesInstead ? 'Subregion' : 'Region'}, ${regionMap.name}(${regionMap.id}).  Districts: ${regionMap.districts[row.district_name].name}(${regionMap.districts[row.district_name].id}), ${row.district_name}(${row.id})`)

    regionMap.districts[row.district_name] = {
      id: row.id,
      name: row.district_name,
      alt_names: row.district_alt_names
    }
  }

  const result: Record<string, Record<string, number>> = {}

  const tempRegionMap: Record<string, Record<string, number>> = {}

  for(const [regionName, region] of Object.entries(tempMap)) {
    const districtMap: Record<string, number> = {}
    for (const district of Object.values(region.districts)) {
      for(const districtAltName of district.alt_names) {
        if (districtMap[districtAltName])
          throw new Error(`District alt-name, ${districtAltName}(for district ${district.name}(${district.id})), conflicts with another district or district alt-name with the same name in ${useSubRegionNamesInstead ? 'subregion' : 'region'} ${regionName}`)
        districtMap[districtAltName] = district.id
      }
    }

    for (const [districtName, district] of Object.entries(region.districts)) {
      if (districtMap[districtName])
        throw new Error(`District, ${districtName}(${district.id}), conflicts with another district or district alt-name with the same name in ${useSubRegionNamesInstead ? 'subregion' : 'region'} ${regionName}`)
      districtMap[districtName] = district.id
    }

    for(const regionAltName of region.alt_names) {
      if (result[regionAltName])
        throw new Error(`${useSubRegionNamesInstead ? 'Subregion' : 'Region'} alt-name, ${regionAltName}, conflicts with another ${useSubRegionNamesInstead ? 'subregion' : 'region'} name or alt-name`)
      result[regionAltName] = districtMap
    }
    if (tempRegionMap[regionName])
      throw new Error(`${useSubRegionNamesInstead ? 'Subregion' : 'Region'} name, ${regionName}, conflicts with another ${useSubRegionNamesInstead ? 'subregion' : 'region'} name`)
    tempRegionMap[regionName] = districtMap
  }

  for(const [regionName, districtMap] of Object.entries(tempRegionMap)) {
    if (result[regionName])
      throw new Error(`${useSubRegionNamesInstead ? 'Subregion' : 'Region'} alt-name, ${regionName}, conflicts with another ${useSubRegionNamesInstead ? 'subregion' : 'region'} name or alt-name`)
    result[regionName] = districtMap
  }

  return result
}



function build1LevelDistrictMap(districtRows: DbResponseResultRow[]): Record<string, number> {
  const twoLevelMap = build2LevelDistrictMap(districtRows)

  const result: Record<string, number> = {}
  for (const districtMap of Object.values(twoLevelMap)) {
    for (const [districtName, districtId] of Object.entries(districtMap)) {
      result[districtName] = districtId
    }
  }

  return result
}



function build2LevelRequestDistrictMap(requestDistricts: FormikValues[]): Record<string, Record<string, number>> {
  const regions = groupBy(requestDistricts, r => r.jrsm_region_name.toLowerCase())

  const result: Record<string, Record<string, number>> = {}

  for (const [regionName, requestDistrictRowsInRegion] of Object.entries(regions)) {
    const requestDistricts = groupBy(requestDistrictRowsInRegion, r => r.jrsm_district_name.toLowerCase())
    const requestDistrictMap: Record<string, number> = {}
    for (const [districtName, requestDistrictRowsInDistrict] of Object.entries(requestDistricts)) {
      requestDistrictMap[districtName] = requestDistrictRowsInDistrict[0].id as number
    }

    result[regionName] = requestDistrictMap
  }

  return result
}


function build1LevelRequestDistrictMap(requestDistricts: FormikValues[]): Record<string, number> {
  const twoLevelMap = build2LevelRequestDistrictMap(requestDistricts)

  const result: Record<string, number> = {}
  for (const [regionName, districtMap] of Object.entries(twoLevelMap)) {
    for (const [districtName, requestDistrictId] of Object.entries(districtMap)) {
      result[regionName ?? districtName] = requestDistrictId
    }
  }

  return result
}




async function matchRequestDistricts(jrsmParsedRows: JRSMParseResultSection[], existingRequestDistricts: FormikValues[], session: SessionData, applicationId: number): Promise<FormikValues[]> {

  const rows = jrsmParsedRows.map(r => {
    const result: FormikValues = { }
    if (r.fields)
      try {
        for (const cell of r.fields) {
            if (cell.db)
              result[cell.name] = cell.value ?? ''
            else if (cell.name === 'district')
              result['jrsm_district_name'] = (cell.value as string)?.trim() ?? ''
            else if (cell.name === 'region')
              result['jrsm_region_name'] = (cell.value as string)?.trim() ?? ''
        }
      } catch (e) {
        console.error(e)
        console.error(r.fields)
        throw e
      }
    return result
  })

  const districtRows = await new RequestDistrictDataDao(session).queryDistricts({applicationId})
  const ethiopiaCountryId = 29
  const useSubRegionNamesInstead = districtRows.some(r => r.country_id === ethiopiaCountryId)

  if (rows.every(d => !!d.jrsm_district_name && !!d.jrsm_region_name)) {

    const districtMap = build2LevelDistrictMap(districtRows, useSubRegionNamesInstead)
    const requestDistrictMap = build2LevelRequestDistrictMap(existingRequestDistricts)

    for(const row of rows) {

      if ((districtMap[row.jrsm_region_name.toLowerCase()]??{})[row.jrsm_district_name.toLowerCase()]) {
        row.district_id = districtMap[row.jrsm_region_name.toLowerCase()][row.jrsm_district_name.toLowerCase()]
      }

      if ((requestDistrictMap[row.jrsm_region_name.toLowerCase()]??{})[row.jrsm_district_name.toLowerCase()]) {
        row.id = requestDistrictMap[row.jrsm_region_name.toLowerCase()][row.jrsm_district_name.toLowerCase()]
      }

    }

  } else {

    const districtMap = build1LevelDistrictMap(districtRows)
    const requestDistrictMap = build1LevelRequestDistrictMap(existingRequestDistricts)

    for(const row of rows) {
      if (row.jrsm_district_name === '')
        row.jrsm_district_name = undefined
      if (row.jrsm_region_name === '')
        row.jrsm_region_name = undefined

      if (districtMap[(row.jrsm_district_name ?? row.jrsm_region_name).toLowerCase()]) {
        row.district_id = districtMap[(row.jrsm_district_name ?? row.jrsm_region_name).toLowerCase()]
      }

      if (requestDistrictMap[(row.jrsm_district_name ?? row.jrsm_region_name as string).toLowerCase()]) {
        row.id = requestDistrictMap[(row.jrsm_district_name ?? row.jrsm_region_name as string).toLowerCase()]
      }

    }
  }

  const duplicate_district_ids = keys(pickBy(groupBy(rows.filter(r => !!r.district_id), r => r.district_id), x => x.length > 1)).map(k => parseInt(k, 10))
  if (duplicate_district_ids.length > 0)
  {
    const duplicateRows = rows.filter(r => duplicate_district_ids.some(id => id === r.district_id))
    throw new Error(`Check the district alt-names.  The following districts matched multiple rows:\n${duplicateRows.map(row => `${row.jrsm_region_name} -> ${row.jrsm_district_name}`).join()}`)
  }



  return rows
}
