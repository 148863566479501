import {Api} from './Api';
import {SessionData} from './Session';

export class LoginDao extends Api {
  constructor(sessionData: SessionData) { super(sessionData) }


  public async login(): Promise<void> {
    await this.execute('SELECT CURRENT_TIME')
  }
}
