import {useEffect, useState, StrictMode, ReactNode} from 'react';
import {
  createBrowserRouter,
  createRoutesFromElements,
  RouterProvider, 
  Routes,
  Route
} from "react-router-dom";

import {Session, SessionData} from './api'
import { AppContext } from './context';
import { NoMatch, Login, Applications } from './pages';
import { ApplicationView } from './pages/application';
import { Container } from './components';

// Test import file parsing, remove when done:
import { TestParse } from './parser/TestParse'

function App() {

  const [session, setSession] = useState<SessionData | null>(null)
  const [isLoading, setIsLoading] = useState(true)


  useEffect(() => {
    const savedSession = new Session().get()
    if (savedSession)
      setSession(savedSession)

    setIsLoading(false)
  }, [])


  const saveSession = (session: SessionData) => {
    setSession(session)
    new Session().set(session)
  }

  const logout = () => {
    new Session().set(null)
    setSession(null)
  }

  const container = (node: ReactNode) => (
    <Container title="MDP Applications">
      {node}
    </Container>
  )

  const router = createBrowserRouter(
    createRoutesFromElements(
      <>
        <Route path="/testapplicationupload"
          element={container(<TestParse/>)}
        />
        <Route path="/applications/:id/:tab/:shipment_id"
               element={container(<ApplicationView />)}
        />
        <Route path="/applications/:id/:tab"
               element={container(<ApplicationView />)}
        />
        <Route path="/applications/:id"
               element={container(<ApplicationView />)}
        />
        <Route path="/applications"
               element={container(<ApplicationView />)}
        />
        <Route path="/"
          element={container(<Applications />)}
        />
        <Route path="*"
          element={container(<NoMatch />)}
        />
      </>
    )
  );

  return (
    <>
      {!isLoading &&
        <StrictMode>
          { session?.user ? (
          <AppContext.Provider value={{ session, logout }}>
            <RouterProvider router={router} />
          </AppContext.Provider>
        ) : (
          <Container title="MDP Applications">
            <Login setSession={saveSession} />
          </Container>
        )}
        </StrictMode>
      }
    </>
  );
}

export default App;
