import {PagingParams} from '../api';

export function Pager(
  {
    count,
    pager,
    setOffset,
  } :{
    count: number
    pager: PagingParams
    setOffset: (o: number) => void
  }): JSX.Element {

  let pages = [...Array(Math.ceil(count / pager.limit)).keys()]
  if (pages.length === 0)
    pages.push(0)

  return (

    <div className="row">
      <div className="d-flex justify-content-center">
        <div className="align-self-center">{count} Results</div>
        <div className="align-self-center ps-4 pe-2">Page</div>
        <div className="align-self-center">{pages.map(p => <button key={p} onClick={() => setOffset(p * pager.limit)} type="button" className="btn btn-link px-2" disabled={p * pager.limit === pager.offset}>{p + 1}</button>)}</div>
      </div>
    </div>
  )
}
