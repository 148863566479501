import {Api} from './Api';
import {FormikValues} from 'formik';
import {FileData} from '../components/form';
import {SessionData} from './Session';

export class RequestDao extends Api {
  constructor(sessionData: SessionData) { super(sessionData) }

  public async get(id: number | undefined): Promise<FormikValues | undefined> {
    if (typeof id === 'undefined')
      return undefined

    const rows = await this.execute(`
        select 
            r.*,
            f.file_name,
            f.mime_type,
            f.id AS file_id,
            f.upload_date AS file_upload_date
        from public.requests r
        left join public.files f ON r.file_id = f.id
        where r.id = :id
    `, {id})


    const contactRows = await this.execute(`SELECT * FROM public.request_contacts b WHERE b.request_id = :id`, {id})
    const notTargetedRows = await this.execute(`SELECT * FROM public.request_not_targeted b WHERE b.request_id = :id`, {id})
    const fundingRows = await this.execute(`SELECT * FROM public.request_funding b WHERE b.request_id = :id`, {id})
    const contributorRows = await this.execute(`SELECT * FROM public.request_contributors b WHERE b.request_id = :id`, {id})
    const districtRows = await this.execute(`SELECT * FROM public.request_district_data b WHERE b.request_id = :id`, {id})


    return {
      ...this.nullsToEmptyStrings(rows[0]),
      contacts: contactRows.map(b => this.nullsToEmptyStrings(b)!),
      notTargeted: notTargetedRows.map(b => this.nullsToEmptyStrings(b)!),
      funding: fundingRows.map(b => this.nullsToEmptyStrings(b)!),
      contributors: contributorRows.map(b => this.nullsToEmptyStrings(b)!),
      districts: districtRows.map(b => this.nullsToEmptyStrings(b)!),
      files: []
    }
  }

  public async save(values: FormikValues,
                    application_id: number,
                    file: FileData | undefined): Promise<number> {


    const jsonParam = {
      request : {
        ...this.emptyStringsToNulls(values),
        application_id,
      },
      file: await this.toDbFile(file),
    }

    const r = await this.execute('SELECT app.saveRequest(:json) AS id', {json: JSON.stringify(jsonParam)})

    return r[0].id as number
  }


}
