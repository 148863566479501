import {
  Api,
  DbLiteralTypes,
  DbResponseResultSet, PagingParams, SortingInfo, WhereBuilder
} from './Api';
import {SessionData} from '../api';

export type Shipper = {
  id: number
  name: string
};

export class ShipperDao extends Api {
  constructor(sessionData: SessionData) { super(sessionData) }

  public async getShippers(): Promise<Shipper[]> {
    return await this.execute(`
      select id, name from public.shippers order by name;
    `) as Shipper[]
  }

}
