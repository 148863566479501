import { mapKeys } from 'lodash'

export function FormError({
  className,
  error
}: {
  className?: string
  error: string | undefined
}) {
  if (error && error.length > 0) {
    return (
      <div 
        className={className || "alert alert-danger"}
        role="alert"
      >
        {error}
      </div>
    )
  } else {
    return <></>
  }
}
