import {useRef} from 'react';
import {FileDao} from '../api';
import {useAppContext} from '../context';

export function DownloadButton(
  {
    fileName,
    fileId,
    label,
  } : {
    fileName: string | undefined
    fileId: number | undefined
    label: string
  }
): JSX.Element {

  const { session } = useAppContext()

  const downloadLink = useRef<HTMLAnchorElement | null>(null)

  function download() {
    (async () => {
      const anchor = downloadLink.current
      if (anchor && fileId) {
        if (anchor.href == '') {
          const url = await new FileDao(session).downloadAsDataUrl(fileId ?? 0)
          if (url) {
            anchor.href = url;
          }
        }
        anchor.click();
      }
    })().catch(e => alert(e.message))
  }


  return (
    <>
      {fileId && <button className="btn btn-link float-end" type="button" onClick={download}>{label}</button>}
      <a className="d-none" ref={downloadLink} target="_blank" download={fileName} />
    </>
  )
}
