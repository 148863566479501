import {ReactNode, useEffect, useMemo, useState} from 'react'
import {Link} from 'react-router-dom';

export type TabItem = {
  title: string
  active?: boolean
  href: string
  node: ReactNode
}

export function Tabs({
  className,
  items
}: {
  className?: string
  items: TabItem[]
}) {

  const tabs = items.map(({title, href, active}, index) => (
    <li className="nav-item" key={index}>
      <Link className={`nav-link ${active ? 'active' : ''}`} 
        to={href}
      >
        {title}
      </Link>
    </li>
  ))
  return (
    <div className={`Tabs ${className ? className : ''}`}>
      <div className="card-header">
        <ul className="nav nav-tabs card-header-tabs">
          {tabs}
        </ul>
      </div>
      {items.filter(item => item.active).map(item => item.node)}
    </div>
  )
}
