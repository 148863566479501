import {LoadablePane} from '../../components';
import {
  FileData,
  FormButtons,
  FormError,
  LabeledFileInput,
  LabeledInput,
  SafeForm
} from '../../components/form';
import {Form, Formik, FormikValues} from 'formik';
import {ApplicationApprovalDao} from '../../api';
import {useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import {useAppContext} from '../../context';
import {inTryCatch} from '../../lib';

export function ApplicationApprovals(): JSX.Element {

  const params = useParams()
  const application_id = +(params.id ?? 0)
  const { session } = useAppContext()

  const [approvals, setApprovals] = useState<FormikValues>()
  const [loading, setLoading] = useState<boolean>(true)
  const [error, setError] = useState<string>()
  const [forceReload, setForceReload] = useState(0)
  const [approvalLtrFile, setApprovalLtrFile] = useState<FileData>()
  const [approvalLtrEmailFile, setApprovalLtrEmailFile] = useState<FileData>()
  const [acknowledgementFile, setAcknowledgementFile] = useState<FileData>()


  useEffect(inTryCatch(setLoading, setError, async (state) => {
    const req = await new ApplicationApprovalDao(session).get(application_id) ?? {}
    if (!state.mounted) return


    setApprovals(req)

    if (req.approval_letter_file_id)
      setApprovalLtrFile({
        file_id: req.approval_letter_file_id,
        name: req.approval_letter_file_name,
        type: req.approval_letter_mime_type,
        upload_date: req.approval_letter_file_upload_date,
      })
    else
      setApprovalLtrFile(undefined)

    if (req.approval_letter_email_file_id)
      setApprovalLtrEmailFile({
        file_id: req.approval_letter_email_file_id,
        name: req.approval_letter_email_file_name,
        type: req.approval_letter_email_mime_type,
        upload_date: req.approval_letter_email_file_upload_date,
      })
    else
      setApprovalLtrEmailFile(undefined)

    if (req.acknowledgement_file_id)
      setAcknowledgementFile({
        file_id: req.acknowledgement_file_id,
        name: req.acknowledgement_file_name,
        type: req.acknowledgement_mime_type,
        upload_date: req.acknowledgement_file_upload_date,
      })
    else
      setAcknowledgementFile(undefined)

  }), [session, application_id, forceReload])

  const totalTreatmentsApproved = (+(approvals?.treatments_approved_lf ?? 0))
      + (+(approvals?.treatments_approved_oncho ?? 0))
      + (+(approvals?.treatments_approved_oncho_r2 ?? 0))
      + (+(approvals?.treatments_approved_lf_oncho ?? 0))

  const totalTabletsNeeded = (+(approvals?.tablets_needed_lf ?? 0))
    + (+(approvals?.tablets_needed_oncho ?? 0))
    + (+(approvals?.tablets_needed_oncho_r2 ?? 0))
    + (+(approvals?.tablets_needed_lf_oncho ?? 0))

  return (
    <div className="card-body">
      <LoadablePane loading={loading || !approvals}>

        <Formik
          initialValues={approvals!}
          onSubmit={async (values, { setSubmitting }) => {
            try {
              setError(undefined)
              await new ApplicationApprovalDao(session).save(values, application_id, approvalLtrFile, approvalLtrEmailFile, acknowledgementFile)
              setForceReload(p => p + 1)
              setSubmitting(false)
            } catch (e: any) {
              setError(e.message)
            }
          }}
        >
          {({ isSubmitting, dirty }) => (
            <SafeForm dirty={dirty} className="container">
              <div className="row">
                <h5 className="col-6">Treatments Approved</h5>
                <h5 className="col-6">Tablets Needed</h5>
              </div>
              <div className="row">
                <LabeledInput name="treatments_approved_lf" side integer className="col-6" label="LF" 
                  labelClass="col-md-4 text-md-end" fieldClass="col-md-8"
                />
                <LabeledInput name="tablets_needed_lf" side integer className="col-6" label="LF" 
                  labelClass="col-md-4 text-md-end" fieldClass="col-md-8"
                />
              </div>
              <div className="row">
                <LabeledInput name="treatments_approved_oncho" side integer className="col-6" label="Oncho" 
                  labelClass="col-md-4 text-md-end" fieldClass="col-md-8"
                 />
                <LabeledInput name="tablets_needed_oncho" side integer className="col-6" label="Oncho" 
                  labelClass="col-md-4 text-md-end" fieldClass="col-md-8"
                />
              </div>
              <div className="row">
                <LabeledInput name="treatments_approved_oncho_r2" side integer className="col-6" label="Oncho R2" 
                  labelClass="col-md-4 text-md-end" fieldClass="col-md-8"
                />
                <LabeledInput name="tablets_needed_oncho_r2" side integer className="col-6" label="Oncho R2"  
                  labelClass="col-md-4 text-md-end" fieldClass="col-md-8"
                />
              </div>
              <div className="row">
                <LabeledInput name="treatments_approved_lf_oncho" side integer className="col-6" label="LF/Oncho"  
                  labelClass="col-md-4 text-md-end" fieldClass="col-md-8"
                />
                <LabeledInput name="tablets_needed_lf_oncho" side integer className="col-6" label="LF/Oncho"  
                  labelClass="col-md-4 text-md-end" fieldClass="col-md-8"
                />
              </div>
              <div className="row">
                <div className="col-6">
                  <div className="row">
                    <div className="col-sm-4 fw-bold text-md-end">Total</div>
                    <div className="col-sm-8 text-end fw-bold">{totalTreatmentsApproved}</div>
                  </div>
                </div>
                <div className="col-6">
                  <div className="row">
                    <div className="col-sm-4 fw-bold text-md-end">Total</div>
                    <div className="col-sm-8 text-end fw-bold">{totalTabletsNeeded}</div>
                  </div>
                </div>
              </div>
              <hr />
              <div className="row">
                <h5 className="col">Tablets</h5>
              </div>
              <div className="row">
                <LabeledInput name="tablets_leftover_in_stock" className="col-md-8" side integer label="Leftover in stock"  
                  labelClass="col-md-4 text-md-end" fieldClass="col-md-8"
                />
              </div>
              <div className="row">
                <LabeledInput name="tablets_approved" className="col-md-8" side integer label="Approved" 
                  labelClass="col-md-4 text-md-end" fieldClass="col-md-8"/>
              </div>
              <div className="row">
                <LabeledInput name="tablets_shipped" className="col-md-8" side integer label="Shipped" 
                  labelClass="col-md-4 text-md-end" fieldClass="col-md-8"/>
              </div>
              <hr />
              <div className="row">
                <LabeledFileInput value={approvalLtrFile} onChange={setApprovalLtrFile} 
                  className="col-12 mb-2" side label="Approval Letter" 
                  labelClass="col-md-3 fw-bold text-md-end me-2" fieldClass="col-md-9"
                />
              </div>
              <div className="row">
                <LabeledFileInput value={approvalLtrEmailFile} onChange={setApprovalLtrEmailFile} 
                  className="col-12 mb-2" side label="Approval Letter Email"
                  labelClass="col-md-3 fw-bold text-md-end me-2" fieldClass="col-md-9"
                />
              </div>
              <div className="row">
                <LabeledFileInput value={acknowledgementFile} onChange={setAcknowledgementFile} 
                  className="col-12 mb-2" side label="Acknowledgement"
                  labelClass="col-md-3 fw-bold text-md-end me-2" fieldClass="col-md-9"
                />
              </div>
              <FormButtons className="col-12 mt-2"
                 buttons={[
                   {
                     label: 'Submit',
                     disabled: isSubmitting || loading,
                     submitting: isSubmitting
                   }, {
                     label: 'Cancel',
                     type: 'button',
                     className: 'btn btn-default',
                     onClick: () => setForceReload(p => p + 1),
                     disabled: isSubmitting || loading
                   }
                 ]}
              />
            </SafeForm>
          )}
        </Formik>
      </LoadablePane>
      <FormError error={error} />
    </div>
  )
}
