import {ChangeEvent, MouseEvent, useRef, useState} from 'react'
import {FileEarmarkArrowUpFill} from '../../components/icons';
import {SavedFileData, FileDao} from '../../api';
import {useAppContext} from '../../context';


export interface NewFileData {
  name: string
  type: string
  file: File
}

export type FileData = SavedFileData | NewFileData

function deduceType(file: File) {
  if (file.type) return file.type

  return file.name.endsWith('.xlsm') ? 'application/vnd.ms-excel.sheet.macroEnabled.12'
    : '';
}

export function FileInput(
  {
    className,
    onChange,
    value,
    uploadText,
    unChangeable
  } : {
    className?: string
    onChange: (data: NewFileData) => void
    value: FileData | undefined
    uploadText?: string
    unChangeable?: boolean
  }
): JSX.Element {

  const { session } = useAppContext()

  const inputFile = useRef<HTMLInputElement | null>(null)
  const downloadLink = useRef<HTMLAnchorElement | null>(null)

  const [isReading, setIsReading] = useState(false)

  function onClick(e: MouseEvent<HTMLButtonElement>) {
    e.stopPropagation();
    e.preventDefault();
    const input = inputFile.current
    if (input !== null) {
      input.click()
    }
  }



  function onChangeFile(event: ChangeEvent<HTMLInputElement>) {
    (async () => {
      setIsReading(true)
      event.stopPropagation();
      event.preventDefault();
      const file = event.target.files?.item(0)
      if (file) {
        const fileData: NewFileData = {
          name: file.name,
          type: deduceType(file),
          file: file
        }
        onChange(fileData)
      }
    })().catch(e => {
      console.error(e)
    }).finally(() => {
      setIsReading(false)
    })
  }

  function download() {
    (async () => {
      const anchor = downloadLink.current
      if (anchor && value && 'file_id' in value) {
        if (anchor.href == '') {
          const url = await new FileDao(session).downloadAsDataUrl(value.file_id)
          if (url) {
            anchor.href = url;
          }
        }
        anchor.click();
      }
    })().catch(e => alert(e.message))
  }

  if (value && !isReading) {
    return (
      <div className={(className || `py-0`)}>
        <div className="input-group">
          {!unChangeable  &&
            <button className="btn btn-outline-secondary" type="button" onClick={onClick}
              title="Upload a new file"
            >
              <FileEarmarkArrowUpFill size={18} />
            </button>
          }
          <input type="file" hidden ref={inputFile} onChange={onChangeFile} /> 
          <input type="text" className="form-control" readOnly={true} value={value.name} />
          {'file_id' in value &&
            <>
              <a className="d-none" ref={downloadLink} target="_blank" download={value.name} />
              <button className="btn btn-outline-secondary" type="button"
                title={`Download ${value.name}`}
                onClick={download}
              >
                Download
              </button>
            </>
          }
        </div>
      </div>
    )
  } else {
    return (
      <div className={(className || `py-0`)}>
        <button className="btn btn-outline-secondary" 
          onClick={onClick}
          disabled={isReading}
        >
          <FileEarmarkArrowUpFill size={18} style={{marginTop: '-3px'}}/>
          <span className="ms-2">{uploadText || 'Upload'}</span>
        </button>
        <input type="file" style={{display: 'none'}} ref={inputFile} onChange={onChangeFile} />
      </div>
    )
  }
}
   // <button className={(className || `btn py-0`) + ' d-flex justify-content-center align-items-center'}
   //    type="button"
   //    disabled={isReading}
   //    onClick={onClick}
   //  >
   //    <input type="file" ref={inputFile} style={{display: 'none'}} onChange={onChangeFile}/>
   //    <FileEarmarkArrowUpFill size={18} />
   //    <div className="ms-1">
   //      {value?.name ?? 'select a file'}
   //    </div>
   //  </button>
