import { 
  DbLiteralTypes,
  ApplicationStatuses
} from '../api' 

export function ApplicationStatus({
  className,
  status
}: {
  className?: string
  status: DbLiteralTypes
}):JSX.Element {

  function applicationStatus(): string {
    for (let i = 0; i < ApplicationStatuses.length; i++) {
      if (ApplicationStatuses[i].value === status) {
        return ApplicationStatuses[i].label
      }
    }
    return "Unknown Status"
  }

  return (
    <span className={className || ''}>
      {applicationStatus()}
    </span>
  )
}