import React, {useEffect, useState} from 'react';
import { Link, useNavigate } from 'react-router-dom';

import {FieldArrayPanel, LoadablePane} from '../../components';
import {Form, Formik, FormikValues, FieldArray} from 'formik';
import {
  FileData,
  FormButtons,
  FormError,
  LabeledFileInput,
  LabeledInput,
  LabeledSelect,
  LabeledSwitch,
  FileInput,
  DatePickerInput,
  SafeForm
} from '../../components/form';
import {useAppContext} from '../../context';
import {inTryCatch} from '../../lib';
import {
  ShipmentDao,
  ShipperDao,
  Shipper
} from '../../api';



function ShipmentBatch(
  {
    index,
    remove,
  } : {
    index: number
    remove: (i: number) => void
  }
): JSX.Element {
  return (
    <div className="card text-bg-light mb-3">
      <div className="row card-body">
        <LabeledInput name={`batches.${index}.batch_id`} side className="col-12" labelClass="col-md-3" fieldClass="col-md-9" />
        <LabeledInput name={`batches.${index}.manufacturing_date`} side className="col-6" type="date" />
        <LabeledInput name={`batches.${index}.expiration_date`} side className="col-6" type="date" />
        <LabeledInput name={`batches.${index}.num_bottles`} side className="col-6" integer />
        <LabeledInput name={`batches.${index}.num_tablets_received`} side className="col-6" integer />
        <LabeledSwitch name={`batches.${index}.missing`} className="col-6" side />
        <div className="d-flex justify-content-end">
          <button className="btn btn-sm btn-danger" type='button' onClick={() => remove(index)}>Remove batch</button>
        </div>
      </div>
    </div>
  )
}

function newBatch(): FormikValues {
  return {
    batch_id: '',
    manufacturing_date: '',
    expiration_date: '',
    num_bottles: '',
    missing: false,
  }
}

const CloseoutReasonOptions = [
  {value: '', label: ""},
  {value: "refused", label: "Refused"},
  {value: "withdrawn", label: "Withdrawn"},
  {value: "declined", label: "Declined"},
  {value: "closed", label: "Closed"},
  {value: "completed", label: "Completed"}
]


export function Shipment(
  {
    id,
    applicationId,
    assignMyId,
    onDelete,
  } : {
    id: number | string | undefined
    applicationId: number
    assignMyId?: (id: number) => void
    onDelete?: () => void
  }
): JSX.Element {

  const { session } = useAppContext()
  const navigate = useNavigate()

  const [shipmentId, setShipmentId] = useState<number | string | undefined>(id)
  const [shipment, setShipment] = useState<FormikValues>()
  
  const [loading, setLoading] = useState<boolean>(true)
  const [error, setError] = useState<string>()
  const [forceReload, setForceReload] = useState(0)

  const [shippers, setShippers] = useState<Shipper[]>([])

  const [certificateFile, setCertificateFile] = useState<FileData>()
  const [invoiceFile, setInvoiceFile] = useState<FileData>()
  const [shippingMemoFile, setShippingMemoFile] = useState<FileData>()
  const [poEmailFile, setPoEmailFile] = useState<FileData>()
  const [arPackingListFile, setArPackingListFile] = useState<FileData>()
  const [closeoutReceiptFile, setCloseoutReceiptFile] = useState<FileData>()


  useEffect(inTryCatch(setLoading, setError, async (state) => {

    setShipment(undefined)
    setCertificateFile(undefined)
    setInvoiceFile(undefined)
    setShippingMemoFile(undefined)
    setPoEmailFile(undefined)
    setArPackingListFile(undefined)
    setCloseoutReceiptFile(undefined)

    if (shipmentId && shipmentId !== 'new') {
      const data = await new ShipmentDao(session).get(parseInt(shipmentId+''))
      const shipperData = await new ShipperDao(session).getShippers();
      if (!state.mounted) return

      if (data.shipment) {
        setShipment(data.shipment)
        setCertificateFile(data.donationCertificateFile)
        setInvoiceFile(data.invoiceFile)
        setShippingMemoFile(data.shippingMemoFile)
        setPoEmailFile(data.poEmailFile)
        setArPackingListFile(data.arPackingListFile)
        setCloseoutReceiptFile(data.closeoutReceiptFile)
      }
      if (shipperData) {
        setShippers(shipperData)
      }
    } else {
      setShipment({batches: [], closeout: {}})
    }



  }), [session, shipmentId, forceReload])

  function onRemoveShipmentClick() {
    (async () => {
      if (window.confirm('Are you sure you want to delete this shipment?')) {
        if (typeof shipmentId != 'undefined') {
          await new ShipmentDao(session).delete(parseInt(`${shipmentId}`))
          if (onDelete) onDelete()
        }
      }
    })().catch((e: any) => {setError(e.message)})

  }


  return (
    <div className="">
      <Link className="btn btn-sm btn-outline-primary mb-3" 
        to={`/applications/${applicationId}/shipments`}
      >
        &lt; Back to shipments
      </Link>
      <LoadablePane loading={loading || !shipment}>
        <Formik
          initialValues={shipment!}
          onSubmit={async (values, { setSubmitting }) => {
            try {
              setError(undefined)
              const isInsert = (typeof shipmentId === 'undefined' || shipmentId === 'new')
              const newId = await new ShipmentDao(session).save(values, applicationId, certificateFile, invoiceFile, shippingMemoFile, poEmailFile, arPackingListFile, closeoutReceiptFile)
              if (isInsert) {
                setShipmentId(newId)
                setForceReload(p => p + 1)
              } else {
                setForceReload(p => p + 1)
              }
              setSubmitting(false)
            } catch (e: any) {
              setError(e.message)
            }
          }}
        >
          {({ isSubmitting, values, dirty }) => (
            <SafeForm dirty={dirty} className="">
              <div className="row">
                <LabeledInput name="shipment_id" label="Shipment ID" className="col-md-6" />
                <LabeledInput name="po_number" className="col-md-6" label="PO #" />
                <LabeledInput name="total_tablets" className="col-md-4" label="Number of tablets" integer />
                <LabeledInput name="total_bottles" className="col-md-4" label="Number of bottles" integer />
                <LabeledInput name="total_pallets" className="col-md-4" label="Number of pallets" integer />
                <LabeledInput name="value_usd" className="col-md-4" label="Value in $USD" integer />
                <LabeledInput name="donee_name" label="Donee" className="col-md-8" />
              </div>
              <hr/>
              <div className="row">
                <LabeledSelect className="col-md-12" name="shipper_id" label="Shipper">
                    <option value="" key="0"></option>
                  {shippers.map( ({id, name}) => (
                    <option value={id} key={id}>{name}</option>
                  ))}
                </LabeledSelect>
              </div>
              <div className="row">
                <LabeledInput name="airfreight_date" className="col-md-4" label="Date airfreight" type="date" />
                <LabeledInput name="tax_exemption_date" className="col-md-4" label="Date tax exemption" type="date" />
                <LabeledInput name="document_sent_forwarder_date" className="col-md-4" label="Date docs sent" type="date" />
              </div>

              <div className="row">
                <h5>Estimated Shipment Dates</h5>
              </div>
              <div className="row">
                <LabeledInput name="estimated_departure_date" className="col-md-4" label="Departure" type="date" />
                <LabeledInput name="estimated_arrival_date" className="col-md-4" label="Arrival" type="date" />
                <LabeledInput name="estimated_delivery_date" className="col-md-4" label="Delivery" type="date" />
              </div>

              <div className="row">
                <h5>Actual Shipment Dates</h5>
              </div>
              <div className="row">
                <LabeledInput name="actual_departure_date" className="col-md-4" label="Departure" type="date" />
                <LabeledInput name="actual_arrival_date" className="col-md-4" label="Arrival" type="date" />
                <LabeledInput name="actual_delivery_date" className="col-md-4" label="Delivery" type="date" />
              </div>

              <hr/>
              <div className="row">
                <LabeledInput name="invoice_number" className="col-md-9" />
              </div>
              <hr/>
              <div className="row align-items-center mb-2">
                <div className="col-md-2 text-md-end">
                  <label className="col-form-control">Certificate</label>
                </div>
                <div className="col-md-3">
                  <DatePickerInput className="form-control" name="donation_certificate_date" placeholderText="Dated"/>
                </div>
                <div className="col-md-7">
                  <FileInput onChange={setCertificateFile} value={certificateFile}
                    uploadText="Upload certificate"
                  />
                </div>
              </div>
              <div className="row align-items-center mb-2">
                <div className="col-md-2 text-md-end">
                  <label className="col-form-control">Invoice</label>
                </div>
                <div className="col-md-3">
                  <DatePickerInput className="form-control" name="invoice_date" placeholderText="Dated"/>
                </div>
                <div className="col-md-7">
                  <FileInput onChange={setInvoiceFile} value={invoiceFile}
                    uploadText="Upload invoice"
                  />
                </div>
              </div>
              <div className="row align-items-center mb-2">
                <div className="col-md-2 text-md-end">
                  <label className="col-form-control">Shipping Memo</label>
                </div>
                <div className="col-md-3">
                  <DatePickerInput className="form-control" name="shipping_memo_date" placeholderText="Dated"/>
                </div>
                <div className="col-md-7">
                  <FileInput onChange={setShippingMemoFile} value={shippingMemoFile}
                    uploadText="Upload shipping memo"
                  />
                </div>
              </div>
              <div className="row align-items-center mb-2">
                <div className="col-md-2 text-md-end">
                  <label className="col-form-control">PO Email</label>
                </div>
                <div className="col-md-3">
                  <DatePickerInput className="form-control" name="po_email_date" placeholderText="Dated"/>
                </div>
                <div className="col-md-7">
                  <FileInput onChange={setPoEmailFile} value={poEmailFile}
                    uploadText="Upload purchase order email"
                  />
                </div>
              </div>
              <div className="row align-items-center mb-2">
                <div className="col-md-2 text-md-end">
                  <label className="col-form-control">AR Packing List</label>
                </div>
                <div className="col-md-3">
                  <DatePickerInput className="form-control" name="ar_packing_list_date" placeholderText="Dated"/>
                </div>
                <div className="col-md-7">
                  <FileInput onChange={setArPackingListFile} value={arPackingListFile}
                    uploadText="Upload AR packing list"
                  />
                </div>
              </div>
              <hr />
              <FieldArrayPanel
                name="batches"
                title="Batches"
                addTitle="add batch"
                newItem={newBatch}
                element={(i, remove) => <ShipmentBatch key={i} index={i} remove={remove} />}
              />
              <hr />
              <div className="row">
                <h5>Closeout</h5>
              </div>
              <div className="row">
                <LabeledInput name="closeout.closeout_date"  className="col-6" type="date" label="Closeout Date" />
                <LabeledSelect className="col-6"  name="closeout.reason">
                  {CloseoutReasonOptions.map( ({label, value}) => (
                    <option value={value} key={value}>{label}</option>
                  ))}
                </LabeledSelect>
              </div>
              <div className="row">
                <LabeledInput name="closeout.total_tablets_received"  className="col-6" integer />
              </div>
              <div className="row">
                <LabeledInput name="closeout.signed_ar_received_date"  className="col-6" label='Received Country AR' type="date" />
              </div>
              <div className="row align-items-center mb-2">
                <div className="col-md-2 text-md-end">
                  <label className="col-form-control">Country AR</label>
                </div>
                <div className="col-md-3">
                  <DatePickerInput className="form-control" name="closeout.country_signed_ar_date" placeholderText="Dated"/>
                </div>
                <div className="col-md-7">
                  <FileInput onChange={setCloseoutReceiptFile} value={closeoutReceiptFile}
                    uploadText="Upload country AR"
                  />
                </div>
              </div>
              <hr/>
              <div className="row">
                <div className="d-flex justify-content-between">
                  <div></div>
                  <button className="btn btn-sm btn-danger" type='button' onClick={onRemoveShipmentClick}>Remove shipment</button>
                </div>
              </div>
              <hr/>
              <FormButtons className="col-12 mt-2"
                 buttons={[
                   {
                     label: 'Submit',
                     disabled: isSubmitting || loading,
                     submitting: isSubmitting
                   }, {
                     label: 'Cancel',
                     type: 'button',
                     className: 'btn btn-default',
                     onClick: () => navigate(`/applications/${applicationId}/shipments`),
                     disabled: isSubmitting || loading
                   }
                 ]}
              />
            </SafeForm>
          )}
        </Formik>
      </LoadablePane>
      <FormError error={error} />
    </div>
  )
}
